<i18n>
{
  "en": {
    "clear": "Clear filter",
    "update": "Update selected filter",
    "save": "Create new save",
    "title": "Filter",
    "field": "Field",
    "operator": "Operator",
    "value": "Value",
    "remove": "Remove field",
    "add": "Add field",
    "filter": "Filter",
    "true": "True",
    "false": "False",
    "filter-error-title": "Wasn't possible to filter!",
    "filter-error-description": "Check if you selected at least one field type and try again."
  },
  "pt-BR": {
    "clear": "Limpar filtro",
    "update": "Editar filtro selecionado",
    "save": "Salvar novo filtro",
    "title": "Filtro",
    "field": "Campo",
    "operator": "Sinal",
    "value": "Valor",
    "remove": "Remover campo",
    "add": "Adicionar campo",
    "filter": "Filtrar",
    "true": "Verdadeiro",
    "false": "Falso",
    "filter-error-title": "Não foi possível realizar o filtro!",
    "filter-error-description": "Verifique se selecionou pelo menos um tipo de campo e tente novamente."
  }
}
</i18n>

<template>
  <div id="filter_view">
    <filter-save v-if="openedSave" @close="openedSave = false" />
    <transition appear name="fade">
      <we-shadow @click="closeFilter" />
    </transition>
    <transition appear name="slide-left">
      <div id="filter">
        <div class="head">
          <div class="options">
            <we-icon-button
              class="big-mobile"
              v-if="currentFilterSave.id"
              direction="bottom"
              icon="edit"
              :name="$t('update')"
              @click="updateSave"
            />
            <we-icon-button
              class="big-mobile"
              direction="bottom"
              icon="save"
              :name="$t('save')"
              @click="createSave"
            />
            <we-icon-button
              class="big-mobile"
              direction="bottom"
              icon="broom"
              :name="$t('clear')"
              @click="clearFilters"
            />
            <we-icon-button
              class="big-mobile"
              icon="times"
              @click="closeFilter"
            />
          </div>
          <div class="we-title">
            <font-awesome-icon icon="sliders-h" />{{ $t("title") }}
          </div>
          <div class="saves">
            <we-action
              v-for="filter in filterSaves"
              :key="filter.id"
              :class="{ active: filter.id === currentFilterSave.id }"
              @click="selectFilterSave(filter)"
            >
              <template #text>
                <font-awesome-icon
                  v-if="filter.is_public"
                  icon="globe-americas"
                />
                {{ filter.name }}
              </template>
            </we-action>
          </div>
        </div>
        <div class="body">
          <section>
            <div class="fields" v-for="(field, i) in fields" :key="field.id">
              <we-input
                type="global-select"
                v-model="field.filterField"
                :label="$t('field')"
                @input="
                  changeFilterField(field);
                  resetValueAndMask(field);
                "
                :items="fieldsListByComponent"
                :clearable="false"
                cy="filter-field"
              />
              <we-input
                :label="$t('operator')"
                v-model="field.operator"
                type="global-select"
                :items="operatorItems"
                :clearable="false"
                @input="resetValueAndMask(field)"
                hide-details
              />
              <we-input-user
                v-if="field.valueField.type === 'users'"
                :label="$t('value')"
                :value="field.valueField.value"
                @input="field.valueField.value = $event.id"
              />
              <we-input-products
                v-else-if="field.valueField.type === 'products'"
                :label="$t('value')"
                :value="field.valueField.value"
                @input="field.valueField.value = $event.id"
              />
              <we-input-contacts
                v-else-if="field.valueField.type === 'contacts'"
                :label="$t('value')"
                :value="field.valueField.value"
                @input="field.valueField.value = $event.id"
              />
              <we-input-companies
                v-else-if="field.valueField.type === 'companies'"
                :label="$t('value')"
                :value="field.valueField.value"
                @input="field.valueField.value = $event.id"
              />
              <we-input
                v-else
                :type="field.valueField.type"
                v-model="field.valueField.value"
                v-maska="field.valueField.mask"
                :items="field.valueField.items"
                :clearable="false"
                :label="$t('value')"
                hide-details
                cy="filter-value"
              />
              <we-icon-button
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                :name="$t('remove')"
                @click="removeField(i)"
              />
            </div>
            <we-button
              class="disabled"
              :text="$t('add')"
              icon="plus"
              @click="addField"
              cy="add-filter"
            />
          </section>
        </div>
        <div class="bottom active">
          <we-button
            class="green"
            :text="`${$t('filter')} ${name.toLowerCase()}`"
            icon="check"
            @click="doFilter"
            :loading="btnLoading"
            cy="filter"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      idNumber: 1,
      loading: false,
      btnLoading: false,
      searchLoading: false,
      customFieldsInputList: [],
      customFields: [],
      openedSave: false,
      filterSaveIdSelected: null
    };
  },
  computed: {
    currentFilterSave() {
      return this.$store.getters.getCurrentFilterSave;
    },
    filterSaves() {
      return this.$store.getters.getFilterSaves.filter(
        e => e.entity === this.component
      );
    },
    costCenters() {
      return this.$store.getters.getCostCenters;
    },
    operatorItems() {
      return this.$store.getters.getOperatorItems;
    },
    fields() {
      return this.$store.getters.getFields;
    },
    name() {
      return this.$options.filters.entityToPtBr(this.component);
    },
    component() {
      if (this.$route.name === "filterDealInPipeline") {
        return "pipeline";
      } else if (this.$route.name === "filterDeal") {
        return "deal";
      } else if (this.$route.name === "filterCompany") {
        return "company";
      } else if (this.$route.name === "filterContact") {
        return "contact";
      } else if (this.$route.name === "filterUser") {
        return "user";
      } else if (this.$route.name === "filterProduct") {
        return "product";
      } else if (this.$route.name === "filterBill") {
        return "bill";
      } else if (this.$route.name === "filterTaskInTasksList") {
        return "taskslist";
      } else if (this.$route.name === "filterTaskInCalendar") {
        return "calendar";
      }

      return "";
    },
    fieldsListByComponent() {
      let list = [];

      if (["pipeline"].includes(this.component)) {
        list = this.$store.getters.getPipelineFields;
      } else if (["deal"].includes(this.component)) {
        list = this.$store.getters.getDealFields;
      } else if (["company"].includes(this.component)) {
        list = this.$store.getters.getCompanyFields;
      } else if (["user"].includes(this.component)) {
        list = this.$store.getters.getUserFields;
      } else if (["product"].includes(this.component)) {
        list = this.$store.getters.getProductFields;
      } else if (["bill"].includes(this.component)) {
        list = this.$store.getters.getBillFields;
      } else if (["taskslist", "calendar"].includes(this.component)) {
        list = this.$store.getters.getTaskFields;
      } else {
        list = this.$store.getters.getContactFields;
      }

      return [...list, ...this.customFieldsInputList];
    },
    billMethodsList() {
      return this.$store.getters.getBillMethodsList;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billTypesList() {
      return this.$store.getters.getBillTypesList;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    currentPipelineIndex() {
      return this.$store?.getters?.getCurrentPipelineIndex;
    },
    currentPipeline() {
      return this.pipelines[this.currentPipelineIndex];
    },
    pipelinesList() {
      let pipelines = [];
      this.pipelines.forEach(pipeline => {
        pipelines.push({
          text: pipeline.name,
          value: pipeline.id
        });
      });
      return pipelines;
    },
    allStagesList() {
      let allStages = [];
      this.pipelines.forEach(pipeline => {
        pipeline.stages.forEach(stage => {
          allStages.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });
      return allStages;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    taskTypesList() {
      return this.$store.getters.getTasks?.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    billsSort() {
      return this.$store.getters.getBillsSort;
    },
    productsSort() {
      return this.$store.getters.getProductsSort;
    },
    usersSort() {
      return this.$store.getters.getUsersSort;
    },
    companiesSort() {
      return this.$store.getters.getCompaniesSort;
    },
    contactsSort() {
      return this.$store.getters.getContactsSort;
    },
    dealsSort() {
      return this.$store.getters.getDealsSort;
    },
    tasksSort() {
      return this.$store.getters.getTasksSort;
    }
  },
  methods: {
    ...mapActions([
      "filterContactsRequest",
      "filterCompaniesRequest",
      "filterUsersRequest",
      "filterProductsRequest",
      "filterDealsRequest",
      "filterBillsRequest",
      "filterTasksRequest",
      "contactsRequest",
      "companiesRequest",
      "productsRequest",
      "dealsRequest",
      "billsRequest",
      "usersRequest",
      "customFieldsRequest",
      "costCentersRequest",
      "filterSavesRequest",
      "calendarTasksRequest",
      "tasksRequest"
    ]),
    selectFilterSave(filterSave) {
      const filter = { ...filterSave };

      filter.filters = filter.filters.map((e, i) => {
        const field = {
          id: i + 9999,
          filterField: e.field,
          operator: e.operator,
          valueField: {
            value: e.value,
            type: "text",
            mask: "",
            items: []
          }
        };
        return this.changeFilterField(field);
      });

      this.$store.commit("setCurrentFilterSave", filter);
      this.$store.commit("setFields", filter.filters);
    },
    createSave() {
      this.$store.commit("setCurrentFilterSave", {
        entity: this.component,
        is_public: false
      });
      this.openedSave = true;
    },
    updateSave() {
      this.openedSave = true;
    },
    clearFilters() {
      if (this.component === "deal") {
        this.dealsRequest({ page: 1 });
      } else if (this.component === "company") {
        this.companiesRequest({ page: 1 });
      } else if (this.component === "user") {
        this.usersRequest({ page: 1 });
      } else if (this.component === "product") {
        this.productsRequest({ page: 1 });
      } else if (this.component === "bill") {
        this.billsRequest({ page: 1 });
      } else if (this.component === "taskslist") {
        this.filters = [
          {
            field: "customer_id",
            operator: "=",
            value: this.loggedUser.customer_id
          }
        ];
        const payload = {
          filters: this.filters,
          page: 1
        };
        this.filterTasksRequest(payload);
      } else if (this.component === "calendar") {
        this.calendarTasksRequest();
      } else {
        this.contactsRequest({ page: 1 });
      }
      this.$store.commit("setCurrentFilterSave", {});
      this.$store.commit("resetFilters");
      this.$store.commit("setStageDealsQuery", null);
    },
    closeFilter() {
      let menuOpened = false;
      const menus = document.querySelectorAll(".v-menu__content");

      menus.forEach(e => {
        if (e.classList.contains("menuable__content__active")) {
          menuOpened = true;
        }
      });

      this.$store.commit("setCurrentFilterSave", {});

      if (!menuOpened) {
        if (this.component === "pipeline") {
          this.$router.push({ name: "pipelines" });
        } else if (this.component === "deal") {
          this.$router.push({ name: "deals" });
        } else if (this.component === "company") {
          this.$router.push({ name: "companies" });
        } else if (this.component === "user") {
          this.$router.push({ name: "users" });
        } else if (this.component === "product") {
          this.$router.push({ name: "productsConfigs" });
        } else if (this.component === "bill") {
          this.$router.push({ name: "bills" });
        } else if (this.component === "taskslist") {
          this.$router.push({ name: "tasksList" });
        } else if (this.component === "calendar") {
          this.$router.push({ name: "tasksCalendar" });
        } else {
          this.$router.push({ name: "contacts" });
        }
      }
    },
    isCheckbox(field) {
      return field.includes("is_");
    },
    resetValueAndMask(field) {
      field.valueField.value = null;
      field.valueField.mask = "";
    },
    changeFilterField(field) {
      field.valueField.type = "text";

      const customFieldRelated = this.customFields.find(
        e => e.name === field.filterField
      );

      if (customFieldRelated) {
        field.operator = "like";
        field.valueField.type = customFieldRelated.type;
        field.valueField.mask =
          field.valueField.type === "text" ? customFieldRelated.mask : "";

        if (customFieldRelated.values?.length > 0) {
          field.operator = "=";
          field.valueField.items = customFieldRelated.values;
        }
      }

      if (this.isCheckbox(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = [
          {
            text: this.$t("true"),
            value: "true"
          },
          {
            text: this.$t("false"),
            value: "false"
          }
        ];
      }

      if (
        [
          "created_at",
          "start",
          "end",
          "updated_at",
          "birthdate",
          "closed_at",
          "paid_at",
          "due_date"
        ].includes(field.filterField)
      ) {
        field.valueField.type = "date";

        if (field.operator === "like") {
          field.operator = ">";
        }
      }

      if (["total", "subtotal", "amount"].includes(field.filterField)) {
        field.valueField.type = "money";
      }

      if (["document"].includes(field.filterField)) {
        if (this.component === "company") {
          field.valueField.mask = "##.###.###/####-##";
        } else {
          field.valueField.mask = this.$root.$t("cpf-mask");
        }
      }

      if (["phone1"].includes(field.filterField)) {
        field.valueField.mask = this.$root.$t("phone-mask");
      }

      if (["task_type_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = this.taskTypesList;
      }

      if (["user_id", "responsible_user_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "users";
      }

      if (["product_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "products";
      }

      if (["contact_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "contacts";
      }

      if (["company_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "companies";
      }

      if (["cost_center"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = this.costCenters;
      }

      if (this.component === "bill") {
        if (["method"].includes(field.filterField)) {
          field.operator = "=";
          field.valueField.type = "global-select";
          field.valueField.items = this.billMethodsList;
        }

        if (["status"].includes(field.filterField)) {
          field.operator = "=";
          field.valueField.type = "global-select";
          field.valueField.items = this.billStatusList;
        }

        if (["type"].includes(field.filterField)) {
          field.operator = "=";
          field.valueField.type = "global-select";
          field.valueField.items = this.billTypesList;
        }
      }

      if (["pipeline_stage_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = this.allStagesList;
      }

      if (["pipeline_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = this.pipelinesList;
      }

      return field;
    },
    addField() {
      const fieldObject = {
        id: this.idNumber++,
        filterField: "",
        operator: "like",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      };

      this.$store.commit("addFields", fieldObject);
    },
    removeField(index) {
      this.$store.commit("removeField", index);
    },
    async doFilter() {
      this.btnLoading = true;
      this.$store.commit("resetUsersSelected");

      let payload = [];
      this.fields
        .filter(e => e.filterField.trim()?.length > 0)
        .forEach(field => {
          const filter = {
            field: field.filterField,
            operator: field.operator,
            value: field.valueField.value
          };

          if (this.isCheckbox(filter.field)) {
            filter.value = filter.value === "true";
          }

          payload.push(filter);
        });

      if (!payload || payload?.length <= 0) {
        const snackBar = {
          title: this.$t("filter-error-title"),
          description: this.$t("filter-error-description"),
          status: "error"
        };
        this.btnLoading = false;
        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");

        return;
      }

      if (this.component === "pipeline") {
        this.$store.commit("setStageDealsQuery", {
          endpoint: "/filter",
          payload: {
            filters: payload,
            paginate: true
          }
        });
        const exportParams = {
          endpoint: "/cards/export",
          entity: "deals",
          payload: { filters: payload }
        };
        this.$store.commit("setExportParams", exportParams);
      } else if (this.component === "deal") {
        const orderBy = this.dealsSort.orderBy
          ? {
              orderBy: this.dealsSort.orderBy,
              direction: this.dealsSort.direction
            }
          : {};
        await this.filterDealsRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "company") {
        const orderBy = this.companiesSort.orderBy
          ? {
              orderBy: this.companiesSort.orderBy,
              direction: this.companiesSort.direction
            }
          : {};
        await this.filterCompaniesRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "user") {
        const orderBy = this.usersSort.orderBy
          ? {
              orderBy: this.usersSort.orderBy,
              direction: this.usersSort.direction
            }
          : {};
        await this.filterUsersRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "product") {
        const orderBy = this.productsSort.orderBy
          ? {
              orderBy: this.productsSort.orderBy,
              direction: this.productsSort.direction
            }
          : {};
        await this.filterProductsRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "bill") {
        const orderBy = this.billsSort.orderBy
          ? {
              orderBy: this.billsSort.orderBy,
              direction: this.billsSort.direction
            }
          : {};
        await this.filterBillsRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "taskslist") {
        const orderBy = this.tasksSort.orderBy
          ? {
              orderBy: this.tasksSort.orderBy,
              direction: this.tasksSort.direction
            }
          : {};
        await this.filterTasksRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      } else if (this.component === "calendar") {
        await this.calendarTasksRequest({
          filters: payload
        });
      } else {
        const orderBy = this.contactsSort.orderBy
          ? {
              orderBy: this.contactsSort.orderBy,
              direction: this.contactsSort.direction
            }
          : {};
        await this.filterContactsRequest({
          filters: payload,
          page: 1,
          ...orderBy
        });
      }

      this.closeFilter();
      this.$store.commit("setFilterActive", true);
      this.btnLoading = false;
    }
  },
  async mounted() {
    if (["pipeline"].includes(this.component)) {
      await this.customFieldsRequest({ entity: "deal" });
    } else {
      await this.customFieldsRequest({ entity: this.component });
    }

    await this.filterSavesRequest({ entity: this.component });

    if (this.component === "bill") {
      this.costCentersRequest();
    }

    if (["calendar", "taskslist"].includes(this.component)) {
      await this.tasksRequest();
    }

    this.customFields = this.$store.getters.getCustomFields;

    this.customFieldsInputList = this.customFields.map(e => {
      return {
        text: e.label || e.name,
        value: e.name
      };
    });
  }
};
</script>

<style lang="scss">
#filter_view {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

#filter {
  height: calc(100% - 20px);
  width: 600px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--background-1);
  border-radius: 10px;
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.25);
  z-index: 4;
  overflow: hidden;

  @include mobile {
    height: 100vh;
    width: 100vw;
    position: relative;
    top: 0;
    right: 0;
    border-radius: 0;
  }

  &.dragovered {
    .dragover {
      display: flex;
    }
  }

  .dragover {
    background: rgba(var(--background-1-rgb), 0.9);
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--primary);
    font-weight: $semibold;
  }

  .head {
    height: fit-content;
    background: var(--background-2);
    border-bottom: 1px solid var(--line);
    position: relative;
    z-index: 3;
    padding-top: 25px;
    padding-left: 25px;
    padding-bottom: 16px;

    @include mobile {
      padding: 13px 15px;
    }

    .options {
      position: absolute;
      top: 18px;
      right: 10px;
      display: flex;
      z-index: 1;

      @include mobile {
        top: 0;
        right: 0;
      }
    }

    .we-title {
      position: relative;
      bottom: 5px;
      font-size: 22px;
      color: var(--text-1);
      font-weight: $medium;
      padding-right: 100px;
      display: flex;
      align-items: center;

      svg {
        height: 18px;
        margin-right: 8px;
      }
    }

    .saves {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .we-action {
        margin-top: 8px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .body {
    padding: 40px 25px 120px 25px;
    max-height: calc(100vh - 105px);
    overflow-y: auto;
    position: relative;

    @include mobile {
      padding: 20px 15px 120px 15px;
    }

    section {
      margin-bottom: 24px;

      .title {
        display: flex;
        align-items: center;
        font-weight: $semibold;
        color: var(--text-2);
        font-size: 16px !important;
        margin-bottom: 16px;

        .icon {
          width: 30px;
          display: flex;
          align-items: center;

          svg {
            height: 18px;
            width: auto;
          }
        }
      }

      .fields {
        padding-left: 0;
        display: grid;
        grid-template-columns: 160px 120px 180px 40px;
        align-items: flex-end;
        gap: 16px;
        margin-bottom: 14px;

        @include mobile {
          display: block;
          border-bottom: 1px solid var(--line);
          margin-bottom: 20px;
        }

        &:first-child {
          .we-icon-button {
            display: none;
          }
        }

        .we-input {
          @include mobile {
            margin-bottom: 20px;
          }
        }

        .we-icon-button {
          @include mobile {
            margin-bottom: 20px;
          }
        }

        .we-input.pagination .label {
          padding-left: 13px;
        }
      }

      button {
        margin: 0 auto;
        margin-top: 32px;
        grid-column-start: 4;
        grid-column-end: 4;

        &.icon {
          margin-top: 0;
          margin-bottom: 1px;
        }
      }
    }
  }

  .bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 65px;
    border-top: 1px solid var(--line);
    background: var(--background-2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    z-index: 5;

    @include mobile {
      position: fixed;
      bottom: 0;
      height: 50px;
    }

    button {
      min-width: 176px;
    }
  }
}
</style>
